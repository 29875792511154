/**
 * SVG to PNG Converter Utility
 * 
 * This utility provides functions to convert SVG images to PNG format on the client side
 * with customizable dimensions, allowing users to download assets in PNG format
 * without requiring server-side processing.
 */

/**
 * Resolves a URL to ensure it's properly formatted for fetch operations
 * Handles webpack aliases and other relative paths
 * 
 * @param {string} url - The URL to resolve
 * @returns {string} - Resolved URL ready for fetch
 */
export const resolveUrl = (url) => {
  // For absolute URLs, use as-is
  if (url.startsWith('http')) {
    return url;
  }
  
  // For webpack alias paths (@/assets/...), we need to handle them differently
  if (url.startsWith('@/')) {
    // Remove the @/ prefix
    const assetPath = url.substring(2);
    
    // In development mode, assets are served directly from the assets directory
    // Try a few different approaches to get the asset
    const possiblePaths = [
      // Standard webpack public path
      `${process.env.BASE_URL}${assetPath}`,
      // Direct path to assets folder (common in development)
      `${window.location.origin}/src/assets/${assetPath.replace('assets/', '')}`,
      // Another common pattern
      `${window.location.origin}/assets/${assetPath.replace('assets/', '')}`
    ];
    
    console.log(`Trying multiple paths for webpack alias: ${url}`, possiblePaths);
    return possiblePaths[0]; // Start with the first option
  }
  
  // For paths starting with /, ensure they're properly based on origin
  if (url.startsWith('/')) {
    return `${window.location.origin}${url}`;
  }
  
  // For other relative paths
  return `${window.location.origin}/${url}`;
};

/**
 * Fetches an SVG file and converts it to a Blob URL to avoid CORS issues
 * 
 * @param {string} url - The URL of the SVG file
 * @returns {Promise<string>} - Promise resolving to a Blob URL for the SVG
 */
export const fetchSvgAsBlob = async (url) => {
  try {
    // First, resolve the URL to ensure it's properly formatted
    const resolvedUrl = resolveUrl(url);
    console.log(`Fetching SVG from resolved URL: ${resolvedUrl} (original: ${url})`);
    
    // Use appropriate fetch options based on whether it's a cross-origin request
    const isCrossOrigin = resolvedUrl.startsWith('http') && 
                          !resolvedUrl.includes(window.location.hostname);
    
    const fetchOptions = isCrossOrigin ? { mode: 'cors', credentials: 'same-origin' } : {};
    
    // For webpack alias paths, try multiple possible locations
    if (url.startsWith('@/')) {
      // Try different possible paths for webpack assets
      const assetPath = url.substring(2);
      const possiblePaths = [
        // Standard webpack public path
        `${process.env.BASE_URL}${assetPath}`,
        // Direct path to assets folder (common in development)
        `${window.location.origin}/src/assets/${assetPath.replace('assets/', '')}`,
        // Another common pattern
        `${window.location.origin}/assets/${assetPath.replace('assets/', '')}`
      ];
      
      let response = null;
      let svgText = null;
      
      // Try each path until we find one that works
      for (const path of possiblePaths) {
        try {
          console.log(`Attempting to fetch from: ${path}`);
          response = await fetch(path, fetchOptions);
          if (response.ok) {
            svgText = await response.text();
            if (svgText.includes('<svg') && svgText.includes('</svg>')) {
              console.log(`Successfully fetched SVG from: ${path}`);
              const blob = new Blob([svgText], { type: 'image/svg+xml' });
              const blobUrl = URL.createObjectURL(blob);
              console.log(`Created blob URL for SVG: ${blobUrl}`);
              return blobUrl;
            }
          }
        } catch (e) {
          console.log(`Failed to fetch from ${path}:`, e.message);
        }
      }
      
      // If we get here, none of the paths worked
      throw new Error(`Failed to fetch SVG from any of the attempted paths for ${url}`);
    }
    
    // Standard fetch for non-webpack paths
    const response = await fetch(resolvedUrl, fetchOptions);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch SVG: ${response.status} ${response.statusText}`);
    }
    
    const svgText = await response.text();
    
    // Validate that we actually got SVG content
    if (!svgText.includes('<svg') || !svgText.includes('</svg>')) {
      console.error('Invalid SVG content received:', svgText.substring(0, 100) + '...');
      throw new Error('Fetched content does not appear to be a valid SVG');
    }
    
    const blob = new Blob([svgText], { type: 'image/svg+xml' });
    const blobUrl = URL.createObjectURL(blob);
    console.log(`Successfully created blob URL for SVG: ${blobUrl}`);
    return blobUrl;
  } catch (error) {
    console.error(`Error fetching SVG from ${url}:`, error);
    throw error;
  }
};

/**
 * Determines if an SVG URL needs to be fetched as a blob
 * 
 * @param {string} url - The URL of the SVG file
 * @returns {boolean} - Whether the URL should be fetched as a blob
 */
const shouldFetchAsBlob = (url) => {
  // Always fetch as blob for all types of URLs to ensure consistent handling
  return true;
};

// Cache for SVG dimensions to avoid re-fetching
const dimensionsCache = new Map();

// Known dimensions for common SVGs that might be challenging to detect automatically
const knownSvgDimensions = {
  // Map filenames and URLs to their known dimensions
  'nothing_found.svg': { width: 500, height: 400 },
  'NothingHereYet.svg': { width: 520, height: 420 },
  '404.svg': { width: 550, height: 400 },
  'success_crystal.svg': { width: 300, height: 300 },
  'founder_badge.svg': { width: 200, height: 200 },
  'premium_badge.svg': { width: 200, height: 200 },
  'Blip_Dj.svg': { width: 600, height: 500 }
};

/**
 * Extracts dimensions from SVG content using regex
 * This is useful for determining dimensions when loading the image fails
 * 
 * @param {string} svgContent - The SVG content as text
 * @returns {Object|null} - The width and height if found, or null
 */
const extractSvgDimensionsFromContent = (svgContent) => {
  try {
    // Try to extract dimensions from viewBox
    const viewBoxMatch = svgContent.match(/viewBox=["']([^"']*)["']/i);
    if (viewBoxMatch && viewBoxMatch[1]) {
      const [, , width, height] = viewBoxMatch[1].split(/\s+/).map(Number);
      if (width > 0 && height > 0) {
        console.log(`Found dimensions from viewBox: ${width}x${height}`);
        return { width, height };
      }
    }
    
    // Try to extract width/height attributes
    const widthMatch = svgContent.match(/width=["']([^"']*)["']/i);
    const heightMatch = svgContent.match(/height=["']([^"']*)["']/i);
    
    if (widthMatch && heightMatch) {
      const width = parseFloat(widthMatch[1]);
      const height = parseFloat(heightMatch[1]);
      
      if (!isNaN(width) && !isNaN(height) && width > 0 && height > 0) {
        console.log(`Found dimensions from attributes: ${width}x${height}`);
        return { width, height };
      }
    }
    
    return null;
  } catch (e) {
    console.error("Error extracting SVG dimensions from content:", e);
    return null;
  }
};

/**
 * Preloads an SVG image to ensure it's in the browser cache
 * 
 * @param {string} url - URL of the SVG image
 * @returns {Promise<void>}
 */
export const preloadSvg = async (url) => {
  try {
    // Check if we already have dimensions in the cache
    if (dimensionsCache.has(url)) {
      return;
    }
    
    // Use our more reliable dimension detection which now prioritizes fetching content
    try {
      const dimensions = await getDimensionsByRendering(url);
      if (dimensions && dimensions.width > 1 && dimensions.height > 1) {
        dimensionsCache.set(url, dimensions);
        console.log(`Preloaded SVG and cached dimensions: ${dimensions.width}x${dimensions.height}`);
        return;
      }
    } catch (error) {
      console.warn(`Error detecting dimensions during preload: ${error.message}`);
      // Don't cache invalid dimensions
    }
    
    // Still preload the image in the background to ensure it's in the browser cache
    const resolvedUrl = resolveUrl(url);
    const preloadImg = new Image();
    preloadImg.crossOrigin = 'Anonymous';
    
    // Create a promise to track when preloading is complete
    return new Promise((resolve) => {
      const cleanup = () => {
        resolve();
      };
      
      preloadImg.onload = () => {
        // Only store dimensions if they're valid and we don't already have them
        if (!dimensionsCache.has(url) && preloadImg.width > 1 && preloadImg.height > 1) {
          dimensionsCache.set(url, {
            width: preloadImg.width,
            height: preloadImg.height
          });
          console.log(`Cached dimensions from preload: ${preloadImg.width}x${preloadImg.height}`);
        }
        setTimeout(cleanup, 100);
      };
      
      preloadImg.onerror = cleanup;
      
      // Set source to trigger loading
      preloadImg.src = resolvedUrl;
      
      // Set a timeout in case loading takes too long
      setTimeout(cleanup, 5000);
    });
  } catch (e) {
    console.warn(`Failed to preload SVG ${url}:`, e);
  }
};

/**
 * Gets the dimensions of an SVG image using a direct DOM rendering approach
 * 
 * @param {string} url - URL of the SVG image
 * @returns {Promise<{width: number, height: number}>} - Promise resolving to dimensions
 */
const getDimensionsByRendering = (url) => {
  return new Promise((resolve) => {
    // First try to fetch and extract dimensions from the SVG content
    fetch(resolveUrl(url))
      .then(response => {
        if (response.ok) {
          return response.text();
        }
        throw new Error('Failed to fetch SVG content');
      })
      .then(svgContent => {
        // Try to extract dimensions from SVG content
        const extractedDimensions = extractSvgDimensionsFromContent(svgContent);
        if (extractedDimensions) {
          console.log(`Successfully extracted dimensions from SVG content: ${extractedDimensions.width}x${extractedDimensions.height}`);
          return resolve(extractedDimensions);
        }
        
        // If we couldn't extract dimensions from content, continue with rendering approach
        throw new Error('Could not extract dimensions from SVG content');
      })
      .catch(error => {
        console.warn(`Extraction from content failed: ${error.message}. Trying fallback methods.`);
        
        // Check if we have known dimensions for this SVG as a fallback
        const filename = url.split('/').pop();
        if (knownSvgDimensions[filename]) {
          console.log(`Using known dimensions for ${filename}: ${knownSvgDimensions[filename].width}x${knownSvgDimensions[filename].height}`);
          return resolve(knownSvgDimensions[filename]);
        }
        
        // For CDN URLs, also check by filename
        for (const [key, dimensions] of Object.entries(knownSvgDimensions)) {
          if (url.includes(key)) {
            console.log(`Using known dimensions for ${url} (matched ${key}): ${dimensions.width}x${dimensions.height}`);
            return resolve(dimensions);
          }
        }
        
        // If no matching known dimensions, proceed with rendering approach
        proceedWithRendering();
      });

    // Function to handle the DOM-based rendering approach
    const proceedWithRendering = () => {
      // Create a temporary container to render the SVG
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.visibility = 'hidden';
      container.style.left = '-9999px';
      container.style.top = '-9999px';
      container.style.width = 'auto';
      container.style.height = 'auto';
      document.body.appendChild(container);
      
      // Create and embed the SVG
      const img = new Image();
      img.style.width = 'auto';
      img.style.height = 'auto';
      img.crossOrigin = 'Anonymous';
      
      // Set a timeout to ensure we don't hang forever
      const timeout = setTimeout(() => {
        cleanup();
        // Return default dimensions if we time out
        resolve({ width: 512, height: 512 });
      }, 3000);
      
      const cleanup = () => {
        clearTimeout(timeout);
        if (document.body.contains(container)) {
          document.body.removeChild(container);
        }
      };
      
      img.onload = () => {
        // Attach to container to ensure it renders
        container.appendChild(img);
        
        // Force a browser reflow to ensure dimensions are calculated
        container.offsetHeight; // eslint-disable-line no-unused-expressions
        
        // Get natural dimensions first
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        
        // If natural dimensions are too small, try actual rendered size
        if (width <= 1 || height <= 1) {
          width = img.offsetWidth;
          height = img.offsetHeight;
        }
        
        // If we still don't have good dimensions, check computed style
        if (width <= 1 || height <= 1) {
          const style = window.getComputedStyle(img);
          width = parseInt(style.width) || 512;
          height = parseInt(style.height) || 512;
        }
        
        cleanup();
        
        // Validate dimensions and provide defaults if necessary
        if (width <= 1 || height <= 1) {
          console.warn(`Failed to get valid dimensions for ${url}, using defaults`);
          resolve({ width: 512, height: 512 });
        } else {
          console.log(`Successfully determined dimensions by rendering: ${width}x${height}`);
          resolve({ width, height });
        }
      };
      
      img.onerror = () => {
        cleanup();
        // Return default dimensions on error
        console.warn(`Error loading image for dimension detection: ${url}`);
        resolve({ width: 512, height: 512 });
      };
      
      img.src = url;
    };
  });
};

/**
 * Gets the dimensions of an SVG image
 * 
 * @param {string} svgUrl - URL of the SVG image
 * @returns {Promise<{width: number, height: number}>} - Promise resolving to the image dimensions
 */
export const getSvgDimensions = async (svgUrl) => {
  // Check cache first
  if (dimensionsCache.has(svgUrl)) {
    console.log(`Using cached dimensions for ${svgUrl}`);
    return dimensionsCache.get(svgUrl);
  }
  
  try {
    console.log(`Getting dimensions for SVG: ${svgUrl}`);
    
    // First attempt: Try the direct rendering approach, which now prioritizes fetching SVG content
    const renderDimensions = await getDimensionsByRendering(svgUrl);
    if (renderDimensions && renderDimensions.width > 1 && renderDimensions.height > 1) {
      dimensionsCache.set(svgUrl, renderDimensions);
      return renderDimensions;
    }
    
    // If that fails, fall back to loading as an image
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    
    // Use a promise to handle image loading
    const dimensions = await new Promise((resolve) => {
      img.onload = () => {
        const width = Math.max(img.width, 2);
        const height = Math.max(img.height, 2);
        resolve({ width, height });
      };
      
      img.onerror = () => {
        resolve({ width: 512, height: 512 });
      };
      
      img.src = resolveUrl(svgUrl);
      
      // Set a timeout to resolve with defaults if loading takes too long
      setTimeout(() => {
        if (img.width <= 1 || img.height <= 1) {
          resolve({ width: 512, height: 512 });
        }
      }, 3000);
    });
    
    // Cache and return the dimensions
    dimensionsCache.set(svgUrl, dimensions);
    return dimensions;
  } catch (error) {
    console.error(`Error getting SVG dimensions: ${error.message}`);
    // Provide fallback dimensions on error
    const fallbackDimensions = { width: 512, height: 512 };
    dimensionsCache.set(svgUrl, fallbackDimensions);
    return fallbackDimensions;
  }
};

/**
 * Converts an SVG to PNG with custom dimensions
 * 
 * @param {string} svgUrl - URL of the SVG image
 * @param {number} width - Desired width of the PNG output
 * @param {number} height - Desired height of the PNG output
 * @param {number} quality - Quality level for PNG conversion (0-1)
 * @returns {Promise<string>} - Promise resolving to a data URL for the PNG image
 */
export const convertSvgToPng = async (svgUrl, width, height, quality = 1.0) => {
  try {
    console.log(`Converting SVG to PNG: ${svgUrl} (${width}x${height}) with quality: ${quality}`);
    
    // Always fetch as blob to avoid issues with direct loading
    let urlToUse = svgUrl;
    let createdBlobUrl = false;
    
    try {
      urlToUse = await fetchSvgAsBlob(svgUrl);
      createdBlobUrl = true;
      console.log(`Using blob URL for conversion: ${urlToUse}`);
    } catch (fetchError) {
      console.warn(`Failed to fetch SVG as blob: ${fetchError.message}. Trying alternate methods.`);
      
      // Try several different approaches
      if (svgUrl.startsWith('@/')) {
        // For webpack aliases, try loading the image directly
        console.log(`Trying direct webpack alias for img.src: ${svgUrl}`);
        urlToUse = svgUrl;
      } else {
        // Fall back to resolved URL if fetch fails
        urlToUse = resolveUrl(svgUrl);
      }
    }
    
    return new Promise((resolve, reject) => {
      // Create a new image to load the SVG
      const svgImg = new Image();
      svgImg.crossOrigin = 'Anonymous';
      
      // Set a timeout to avoid hanging on image loading
      const timeoutId = setTimeout(() => {
        if (createdBlobUrl) {
          URL.revokeObjectURL(urlToUse);
        }
        reject(new Error(`Timeout loading SVG image from ${svgUrl}`));
      }, 15000); // 15 second timeout
      
      svgImg.onload = () => {
        clearTimeout(timeoutId);
        try {
          console.log(`SVG image loaded successfully, creating canvas...`);
          
          // Create a canvas element to draw the SVG
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          
          // Get the canvas context and draw the SVG
          const ctx = canvas.getContext('2d');
          
          // Apply high quality settings for better rendering
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          
          ctx.clearRect(0, 0, width, height);
          ctx.drawImage(svgImg, 0, 0, width, height);
          
          // Convert canvas to PNG data URL with specified quality
          const pngDataUrl = canvas.toDataURL('image/png', quality);
          console.log(`Successfully converted SVG to PNG data URL with quality: ${quality}`);
          
          // Clean up blob URL if we created one
          if (createdBlobUrl) {
            URL.revokeObjectURL(urlToUse);
          }
          
          resolve(pngDataUrl);
        } catch (error) {
          console.error(`Error during canvas rendering: ${error.message}`);
          if (createdBlobUrl) {
            URL.revokeObjectURL(urlToUse);
          }
          reject(new Error(`Error converting SVG to PNG: ${error.message}`));
        }
      };
      
      svgImg.onerror = (error) => {
        clearTimeout(timeoutId);
        console.error(`Failed to load SVG image for conversion: ${urlToUse}`, error);
        
        if (createdBlobUrl) {
          URL.revokeObjectURL(urlToUse);
        }
        
        // Try one more approach - public path
        if (svgUrl.startsWith('@/')) {
          console.log(`Trying public path as last resort for conversion`);
          const publicSvgUrl = svgUrl.replace('@/', '/');
          
          // Create another image element for this attempt
          const publicImg = new Image();
          publicImg.crossOrigin = 'Anonymous';
          
          publicImg.onload = () => {
            console.log(`Public path approach worked for conversion!`);
            
            try {
              // Create canvas and draw the SVG
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              
              const ctx = canvas.getContext('2d');
              ctx.imageSmoothingEnabled = true;
              ctx.imageSmoothingQuality = 'high';
              
              ctx.clearRect(0, 0, width, height);
              ctx.drawImage(publicImg, 0, 0, width, height);
              
              // Convert canvas to PNG data URL with specified quality
              const pngDataUrl = canvas.toDataURL('image/png', quality);
              console.log(`Successfully converted SVG to PNG data URL using public path with quality: ${quality}`);
              
              resolve(pngDataUrl);
            } catch (error) {
              console.error(`Error during canvas rendering with public path: ${error.message}`);
              reject(new Error(`Error converting SVG to PNG with public path: ${error.message}`));
            }
          };
          
          publicImg.onerror = () => {
            console.warn(`Public path approach also failed for conversion.`);
            reject(new Error(`Failed to load SVG image: ${svgUrl}`));
          };
          
          publicImg.src = publicSvgUrl;
          return;
        }
        
        reject(new Error(`Failed to load SVG image: ${svgUrl}`));
      };
      
      // Start loading the SVG
      console.log(`Setting svgImg.src to: ${urlToUse}`);
      svgImg.src = urlToUse;
    });
  } catch (error) {
    console.error('Error in SVG to PNG conversion:', error);
    throw error;
  }
};

/**
 * Downloads a PNG image with a custom filename
 * 
 * @param {string} dataUrl - Data URL of the PNG image
 * @param {string} filename - Desired filename for the download
 */
export const downloadPng = (dataUrl, filename) => {
  try {
    console.log(`Downloading PNG as: ${filename}`);
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(`Error downloading PNG: ${error.message}`);
    throw error;
  }
};

/**
 * Handles the complete process of converting an SVG to PNG and downloading it
 * 
 * @param {string} svgUrl - URL of the SVG image
 * @param {number} width - Desired width of the PNG output
 * @param {number} height - Desired height of the PNG output
 * @param {string|null} filename - Desired filename for the download, if null no download will be triggered
 * @param {number} quality - Quality level for PNG conversion (0-1)
 * @returns {Promise<string|boolean>} - Promise resolving to the data URL if filename is null, or true if download initiated
 */
export const convertAndDownloadPng = async (svgUrl, width, height, filename, quality = 1.0) => {
  try {
    console.log(`Starting SVG to PNG conversion and download process for: ${svgUrl} with quality: ${quality}`);
    const pngDataUrl = await convertSvgToPng(svgUrl, width, height, quality);
    
    // If filename is null, return the data URL without downloading
    if (filename === null) {
      return pngDataUrl;
    }
    
    // Otherwise download as usual
    downloadPng(pngDataUrl, filename);
    console.log(`PNG successfully converted and download initiated`);
    return true;
  } catch (error) {
    console.error('Error converting and downloading PNG:', error);
    throw error;
  }
}; 