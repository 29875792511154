/**
 * Marketing utilities for SVG and image handling
 */

/**
 * Download SVG file directly with proper URL resolution
 * @param {string} svgUrl - The URL of the SVG to download
 * @param {string} filename - The filename for the download
 */
export function downloadSvg(svgUrl, filename) {
  // For absolute URLs, use as-is
  let url = svgUrl;
  if (!svgUrl.startsWith('http')) {
    // For webpack alias paths (@/assets/...), try multiple possible locations
    if (svgUrl.startsWith('@/')) {
      // Since webpack aliases aren't available at runtime, we need to handle this specially
      const assetPath = svgUrl.substring(2); // Remove the @/
      
      // Try to use the PUBLIC_URL from environment if available
      if (process.env.BASE_URL) {
        url = `${process.env.BASE_URL}${assetPath}`;
      } else if (process.env.NODE_ENV === 'development') {
        // In development, we might need different paths
        url = `/src/assets/${assetPath.replace('assets/', '')}`;
      } else {
        // Fallback to a simple public path
        url = `/${assetPath}`;
      }
    }
    // For paths starting with /assets or /
    else if (svgUrl.startsWith('/')) {
      url = window.location.origin + svgUrl;
    }
    // For other relative paths
    else {
      url = window.location.origin + '/' + svgUrl;
    }
  }

  // Create a link element to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Get URL with proper resolution for various path types
 * @param {string} url - The URL to resolve
 * @returns {string} The resolved URL
 */
export function resolveUrl(url) {
  if (url.startsWith('http')) {
    return url;
  } 
  
  if (url.startsWith('@/')) {
    const assetPath = url.substring(2);
    if (process.env.BASE_URL) {
      return `${process.env.BASE_URL}${assetPath}`;
    }
    if (process.env.NODE_ENV === 'development') {
      return `/src/assets/${assetPath.replace('assets/', '')}`;
    }
    return `/${assetPath}`;
  }
  
  if (url.startsWith('/')) {
    return window.location.origin + url;
  }
  
  return window.location.origin + '/' + url;
} 