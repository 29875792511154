<template>
  <div class="marketing-resources">
    <div class="header">
      <h1>Marketing Resources</h1>
      <p class="subtitle">Brand guidelines and assets for marketing team</p>
    </div>

    <v-card class="resource-container">
      <div class="tabs-wrapper">
        <v-tabs
          v-model="activeTab"
          background-color="rgba(40, 44, 71, 0.7)"
          color="#20C4F5"
          dark
          slider-color="#20C4F5"
          height="56"
          class="resource-tabs"
        >
          <v-tab key="colors" class="tab-button">Brand Colors</v-tab>
          <v-tab key="typography" class="tab-button">Typography</v-tab>
          <v-tab key="assets" class="tab-button">Visual Assets</v-tab>
          <v-tab key="embeddings" class="tab-button">Embeddings</v-tab>
          <v-tab key="qrcodes" class="tab-button">QR Codes</v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="activeTab" class="transparent-bg">
        <!-- Brand Colors Tab -->
        <v-tab-item key="colors">
          <v-card flat class="transparent-bg">
            <v-card-text>
              <h2 class="section-title">Color Palette</h2>
              
              <div class="color-grid">
                <!-- Primary Colors -->
                <div class="color-section">
                  <h3>Primary Colors</h3>
                  <div class="colors">
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #20C4F5"></div>
                      <div class="color-info">
                        <div class="color-name">Primary Blue</div>
                        <div class="color-hex">#20C4F5</div>
                      </div>
                    </div>
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #282C47"></div>
                      <div class="color-info">
                        <div class="color-name">Dark Blue</div>
                        <div class="color-hex">#282C47</div>
                      </div>
                    </div>
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #FFFFFF"></div>
                      <div class="color-info">
                        <div class="color-name">White</div>
                        <div class="color-hex">#FFFFFF</div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Secondary Colors -->
                <div class="color-section">
                  <h3>Secondary Colors</h3>
                  <div class="colors">
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #5F6986"></div>
                      <div class="color-info">
                        <div class="color-name">Slate Blue</div>
                        <div class="color-hex">#5F6986</div>
                      </div>
                    </div>
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #353B49"></div>
                      <div class="color-info">
                        <div class="color-name">Gray Blue</div>
                        <div class="color-hex">#353B49</div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <!-- Accent Colors -->
                <div class="color-section">
                  <h3>Accent Colors</h3>
                  <div class="colors">
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #FF5252"></div>
                      <div class="color-info">
                        <div class="color-name">Error Red</div>
                        <div class="color-hex">#FF5252</div>
                      </div>
                    </div>
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #4CAF50"></div>
                      <div class="color-info">
                        <div class="color-name">Success Green</div>
                        <div class="color-hex">#4CAF50</div>
                      </div>
                    </div>
                    <div class="color-item">
                      <div class="color-swatch" style="background-color: #FFC107"></div>
                      <div class="color-info">
                        <div class="color-name">Warning Yellow</div>
                        <div class="color-hex">#FFC107</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h2 class="section-title mt-8">Color Usage Guidelines</h2>
              <div class="usage-guidelines">
                <div class="guideline-card">
                  <h3>Primary Blue</h3>
                  <p>Use for primary action buttons, key highlights, and branded accents. This is our signature color and should be used sparingly for maximum impact.</p>
                </div>
                <div class="guideline-card">
                  <h3>Dark Blues</h3>
                  <p>Use Dark Blue (#282C47) for backgrounds and Gray Blue (#353B49) for cards and sections. These create our distinctive dark mode aesthetic.</p>
                </div>
                <div class="guideline-card">
                  <h3>Accent Colors</h3>
                  <p>Use accent colors only for their intended purposes: red for errors and destructive actions, green for success and confirmation, yellow for warnings and notifications.</p>
                </div>
              </div>

              <h2 class="section-title mt-8">Light & Dark Themes</h2>
              <div class="theme-cards">
                <div class="theme-card">
                  <div class="theme-preview dark-theme">
                    <div class="theme-header"></div>
                    <div class="theme-body">
                      <div class="theme-card-item"></div>
                      <div class="theme-card-item"></div>
                    </div>
                  </div>
                  <h3>Dark Theme (Default)</h3>
                  <p>Our primary theme uses dark backgrounds with light text and bright accents for a modern, immersive experience.</p>
                </div>
                <div class="theme-card">
                  <div class="theme-preview light-theme">
                    <div class="theme-header"></div>
                    <div class="theme-body">
                      <div class="theme-card-item"></div>
                      <div class="theme-card-item"></div>
                    </div>
                  </div>
                  <h3>Light Theme (Alternative)</h3>
                  <p>Our light theme inverts the color relationships for contexts requiring higher readability or print materials.</p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Typography Tab -->
        <v-tab-item key="typography">
          <v-card flat class="transparent-bg">
            <v-card-text>
              <h2 class="section-title">Brand Fonts</h2>
              
              <div class="font-showcase">
                <div class="font-card">
                  <h1 class="font-heading" style="font-family: 'Montserrat', sans-serif;">Montserrat</h1>
                  <p class="font-description">Primary font for headings and display text. Clean, modern, and easily readable at various sizes.</p>
                  <div class="font-sample">
                    <div class="font-weight-sample" style="font-family: 'Montserrat', sans-serif; font-weight: 300;">Montserrat Light (300)</div>
                    <div class="font-weight-sample" style="font-family: 'Montserrat', sans-serif; font-weight: 400;">Montserrat Regular (400)</div>
                    <div class="font-weight-sample" style="font-family: 'Montserrat', sans-serif; font-weight: 500;">Montserrat Medium (500)</div>
                    <div class="font-weight-sample" style="font-family: 'Montserrat', sans-serif; font-weight: 700;">Montserrat Bold (700)</div>
                  </div>
                  <div class="font-download">
                    <a href="https://fonts.google.com/specimen/Montserrat" target="_blank">Download from Google Fonts</a>
                  </div>
                </div>

                <div class="font-card">
                  <h1 class="font-heading" style="font-family: 'Roboto', sans-serif;">Roboto</h1>
                  <p class="font-description">Secondary font for body text and UI elements. Highly legible with a clean, neutral appearance.</p>
                  <div class="font-sample">
                    <div class="font-weight-sample" style="font-family: 'Roboto', sans-serif; font-weight: 300;">Roboto Light (300)</div>
                    <div class="font-weight-sample" style="font-family: 'Roboto', sans-serif; font-weight: 400;">Roboto Regular (400)</div>
                    <div class="font-weight-sample" style="font-family: 'Roboto', sans-serif; font-weight: 500;">Roboto Medium (500)</div>
                    <div class="font-weight-sample" style="font-family: 'Roboto', sans-serif; font-weight: 700;">Roboto Bold (700)</div>
                  </div>
                  <div class="font-download">
                    <a href="https://fonts.google.com/specimen/Roboto" target="_blank">Download from Google Fonts</a>
                  </div>
                </div>
                
                <div class="font-card">
                  <h1 class="font-heading" style="font-family: 'Michroma', sans-serif; text-transform: uppercase;">MICHROMA</h1>
                  <p class="font-description">Distinctive font used specifically for our logo. Geometric and futuristic with a high-tech appearance.</p>
                  <div class="font-sample">
                    <div class="font-weight-sample" style="font-family: 'Michroma', sans-serif; font-weight: 400; text-transform: uppercase;">MICHROMA REGULAR (400)</div>
                    <div class="font-weight-sample" style="font-family: 'Michroma', sans-serif; font-weight: 300; text-transform: uppercase; opacity: 0.85;">MICHROMA LIGHT (300)</div>
                    <div class="font-weight-sample" style="font-family: 'Michroma', sans-serif; font-weight: 500; text-transform: uppercase; letter-spacing: 0.5px;">MICHROMA MEDIUM (500)</div>
                    <div class="font-weight-sample" style="font-family: 'Michroma', sans-serif; font-weight: 700; text-transform: uppercase; letter-spacing: 1px;">MICHROMA BOLD (700)</div>
                  </div>
                  <div class="font-download">
                    <a href="https://fonts.google.com/specimen/Michroma" target="_blank">Download from Google Fonts</a>
                  </div>
                </div>
              </div>

              <h2 class="section-title mt-8">Typography Styles</h2>
              
              <div class="typography-styles">
                <div class="typography-style-card">
                  <h1 class="typography-example" style="font-size: 2.5rem; font-weight: 700;">Header 1</h1>
                  <div class="typography-details">
                    <span>Montserrat Bold</span>
                    <span>40px / 2.5rem</span>
                    <span>Line Height: 1.2</span>
                  </div>
                </div>
                
                <div class="typography-style-card">
                  <h2 class="typography-example" style="font-size: 2rem; font-weight: 600;">Header 2</h2>
                  <div class="typography-details">
                    <span>Montserrat Semibold</span>
                    <span>32px / 2rem</span>
                    <span>Line Height: 1.3</span>
                  </div>
                </div>
                
                <div class="typography-style-card">
                  <h3 class="typography-example" style="font-size: 1.5rem; font-weight: 500;">Header 3</h3>
                  <div class="typography-details">
                    <span>Montserrat Medium</span>
                    <span>24px / 1.5rem</span>
                    <span>Line Height: 1.4</span>
                  </div>
                </div>
                
                <div class="typography-style-card">
                  <p class="typography-example body-1" style="font-size: 1rem; font-family: 'Roboto', sans-serif;">Body Text (Regular)</p>
                  <div class="typography-details">
                    <span>Roboto Regular</span>
                    <span>16px / 1rem</span>
                    <span>Line Height: 1.6</span>
                  </div>
                </div>
                
                <div class="typography-style-card">
                  <p class="typography-example body-2" style="font-size: 0.875rem; font-family: 'Roboto', sans-serif;">Body Text (Small)</p>
                  <div class="typography-details">
                    <span>Roboto Regular</span>
                    <span>14px / 0.875rem</span>
                    <span>Line Height: 1.6</span>
                  </div>
                </div>
                
                <div class="typography-style-card">
                  <span class="typography-example caption" style="font-size: 0.75rem; font-family: 'Roboto', sans-serif;">Caption Text</span>
                  <div class="typography-details">
                    <span>Roboto Regular</span>
                    <span>12px / 0.75rem</span>
                    <span>Line Height: 1.4</span>
                  </div>
                </div>
              </div>

              <h2 class="section-title mt-8">Text Hierarchy</h2>
              
              <div class="text-hierarchy-example">
                <div class="hierarchy-card">
                  <div class="example-content">
                    <h1 style="font-family: 'Montserrat', sans-serif; font-weight: 700; font-size: 2.5rem; margin-bottom: 0.5rem;">Main Event Title</h1>
                    <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 600; font-size: 1.5rem; margin-bottom: 1rem; color: rgba(255,255,255,0.9);">Event Subtitle or Location</h2>
                    <p style="font-family: 'Roboto', sans-serif; font-size: 1rem; margin-bottom: 1.5rem; color: rgba(255,255,255,0.8);">Longer event description text that explains more details about the event, including what attendees can expect, featured artists, and other important information that would be of interest.</p>
                    <div style="font-family: 'Roboto', sans-serif; font-size: 0.875rem; color: rgba(255,255,255,0.7); margin-bottom: 2rem;">
                      <div>Date: September 18, 2023</div>
                      <div>Time: 8:00 PM - 2:00 AM</div>
                      <div>Venue: The Underground Club</div>
                    </div>
                    <button style="background: #20C4F5; color: white; border: none; padding: 0.75rem 2rem; border-radius: 4px; font-weight: 500; font-size: 1rem;">Buy Tickets</button>
                  </div>
                  <div class="annotations">
                    <div class="annotation" style="top: 5%;">Primary Header - Largest, boldest element for main title</div>
                    <div class="annotation" style="top: 20%;">Secondary Header - Supporting information</div>
                    <div class="annotation" style="top: 35%;">Body Text - Descriptive content with high readability</div>
                    <div class="annotation" style="top: 55%;">Supporting Information - Secondary details in smaller type</div>
                    <div class="annotation" style="top: 75%;">Call to Action - Bold, distinctive treatment</div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- Visual Assets Tab -->
        <v-tab-item key="assets">
          <marketing-visual-assets 
            :asset-base-url="assetBaseUrl"
            @open-png-modal="openPngModal" 
          />
        </v-tab-item>

        <!-- Embeddings Tab -->
        <v-tab-item key="embeddings">
          <marketing-embeddings
            :asset-base-url="assetBaseUrl"
          />
        </v-tab-item>

        <!-- QR Codes Tab -->
        <v-tab-item key="qrcodes">
          <v-card flat class="transparent-bg">
            <v-card-text>
              <h2 class="section-title">PsyChurch QR Codes</h2>
              
              <div class="asset-grid">
                <div class="asset-card">
                  <div class="asset-preview light-bg large">
                    <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_blk.svg" alt="PsyChurch QR Code (Black)" class="asset-image" />
                  </div>
                  <div class="asset-info">
                    <h3>PsyChurch QR Code (Black)</h3>
                    <p>Black QR code for <a href="https://qr.psychurch.org" target="_blank">qr.psychurch.org</a>, suitable for light backgrounds, printed materials, and marketing collateral.</p>
                    <div class="download-links">
                      <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_blk.svg', 'psychurch_qr_black.svg')" class="download-button">SVG</button>
                      <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_blk.svg', 'psychurch_qr_black.png')">PNG</button>
                    </div>
                  </div>
                </div>
                
                <div class="asset-card">
                  <div class="asset-preview dark-bg large">
                    <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_wht.svg" alt="PsyChurch QR Code (White)" class="asset-image" />
                  </div>
                  <div class="asset-info">
                    <h3>PsyChurch QR Code (White)</h3>
                    <p>White QR code for <a href="https://qr.psychurch.org" target="_blank">qr.psychurch.org</a>, suitable for dark backgrounds, digital displays, and themed marketing materials.</p>
                    <div class="download-links">
                      <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_wht.svg', 'psychurch_qr_white.svg')" class="download-button">SVG</button>
                      <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_wht.svg', 'psychurch_qr_white.png')">PNG</button>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    
    <!-- PNG Size Modal -->
    <png-size-modal
      v-model="pngModalVisible"
      :svg-url="currentSvgUrl"
      :filename="currentFilename"
    />
  </div>
</template>

<script>
import { downloadSvg } from '@/utils/marketingUtils';
import { preloadSvg } from '@/utils/svgToPngConverter';
import PngSizeModal from '@/components/PngSizeModal.vue';
import MarketingVisualAssets from '@/components/marketing/MarketingVisualAssets.vue';
import MarketingEmbeddings from '@/components/marketing/MarketingEmbeddings.vue';

export default {
  name: 'MarketingResources',
  components: {
    PngSizeModal,
    MarketingVisualAssets,
    MarketingEmbeddings
  },
  metaInfo: {
    title: 'Marketing Resources'
  },
  data() {
    return {
      activeTab: null,
      pngModalVisible: false,
      currentSvgUrl: '',
      currentFilename: '',
      assetBaseUrl: process.env.NODE_ENV === 'production' 
        ? 'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/' 
        : '/'
    }
  },
  mounted() {
    // We'll let the Visual Assets component handle preloading its own SVGs
    // But we need to preload QR code SVGs for the QR codes tab
    this.preloadQrCodeSvgs();
  },
  methods: {
    /**
     * Preload QR code SVGs to ensure they're ready for PNG conversion
     */
    async preloadQrCodeSvgs() {
      const qrCodeUrls = [
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_blk.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/QR_codes/qr.psychurch.org_wht.svg'
      ];
      
      for (const url of qrCodeUrls) {
        try {
          await preloadSvg(url);
          console.log(`Successfully preloaded QR code: ${url}`);
        } catch (error) {
          console.warn(`Failed to preload QR code: ${url}`, error);
        }
      }
    },
    /**
     * Open the PNG Size Modal for SVG to PNG conversion
     * This method is now called both directly and via event from the MarketingVisualAssets component
     * 
     * @param {string|Object} svgUrlOrEvent - Either the SVG URL or an event object with svgUrl and filename
     * @param {string} [filename] - The filename for the download (not needed when called via event)
     */
    openPngModal(svgUrlOrEvent, filename) {
      // Check if this was called via event from the component
      if (typeof svgUrlOrEvent === 'object' && svgUrlOrEvent.svgUrl) {
        console.log(`Opening PNG modal for SVG from event: ${svgUrlOrEvent.svgUrl}`);
        this.currentSvgUrl = svgUrlOrEvent.svgUrl;
        this.currentFilename = svgUrlOrEvent.filename;
      } else {
        // Direct call with separate parameters
        console.log(`Opening PNG modal for SVG: ${svgUrlOrEvent}`);
        this.currentSvgUrl = svgUrlOrEvent;
        this.currentFilename = filename;
      }
      
      this.pngModalVisible = true;
    },
    
    /**
     * Download SVG file directly
     * This is kept in the parent for backward compatibility but is now
     * primarily handled by the MarketingVisualAssets component
     * 
     * @param {string} svgUrl - The URL of the SVG to download
     * @param {string} filename - The filename for the download
     */
    downloadSvg(svgUrl, filename) {
      downloadSvg(svgUrl, filename);
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/marketingAssets.scss';

.marketing-resources {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  .header {
    margin-bottom: 3rem;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
    }
  }
}

.resource-container {
  background-color: #282C47 !important;
  border-radius: 12px;
  padding: 1rem;
}

.tabs-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.resource-tabs {
  margin-bottom: 1rem;
}

.tab-button {
  font-weight: 500;
  letter-spacing: 0.5px;
  padding: 0 1.5rem !important;
  position: relative;
  transition: all 0.2s ease;
  
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 25%;
    height: 50%;
    width: 1px;
    background: rgba(255,255,255,0.1);
  }
  
  &:last-child::after {
    display: none;
  }
}

/* Colors Tab Styles */
.color-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.color-section h3 {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.colors {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.color-item {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}

.color-swatch {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.color-info {
  padding: 1rem;
}

.color-name {
  color: #fff;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.color-hex {
  color: rgba(255, 255, 255, 0.7);
  font-family: monospace;
  font-size: 0.9rem;
}

.usage-guidelines {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.guideline-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
}

.guideline-card h3 {
  color: #fff;
  margin-bottom: 0.75rem;
  font-size: 1.2rem;
}

.guideline-card p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}

.theme-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.theme-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.theme-preview {
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.dark-theme {
  background: #282C47;
}

.light-theme {
  background: #f5f5f7;
}

.theme-header {
  height: 40px;
  background: rgba(95, 105, 134, 0.3);
}

.light-theme .theme-header {
  background: #e1e1e3;
}

.theme-body {
  flex: 1;
  padding: 1rem;
  display: flex;
  gap: 1rem;
}

.theme-card-item {
  flex: 1;
  background: #353B49;
  border-radius: 4px;
}

.light-theme .theme-card-item {
  background: #ffffff;
  border: 1px solid #e1e1e3;
}

.theme-card h3 {
  color: #fff;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.theme-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Typography Tab Styles */
.font-showcase {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2rem;
}

.font-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
}

.font-heading {
  color: #fff;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.font-description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.font-sample {
  margin-bottom: 1.5rem;
}

.font-weight-sample {
  color: #fff;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.font-download a {
  color: #20C4F5;
  text-decoration: none;
}

.typography-styles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.typography-style-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 1.5rem;
}

.typography-example {
  color: #fff;
  margin-bottom: 1rem;
}

.typography-details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.text-hierarchy-example {
  margin-top: 2rem;
}

.hierarchy-card {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 2rem;
  position: relative;
  display: flex;
}

.example-content {
  flex: 3;
  padding-right: 2rem;
}

.annotations {
  flex: 2;
  position: relative;
  border-left: 1px dashed rgba(255, 255, 255, 0.3);
  padding-left: 2rem;
}

.annotation {
  position: absolute;
  left: 2rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  padding-right: 1rem;
}

/* QR Code tab styles - asset specific customizations */
.asset-preview.large {
  height: 280px;
}
</style> 