<template>
  <v-card flat class="transparent-bg">
    <v-card-text>
      <h2 class="section-title">Logos & Variations</h2>
      
      <div class="asset-grid">
        <!-- Full Logos -->
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_ua.svg" alt="Full Logo (Color)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Full Logo (Color)</h3>
            <p>Full color logo for use on dark backgrounds. Our primary brand mark.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_ua.svg', 'full_logo_color.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_ua.svg', 'full_logo_color.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview light-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_blk.svg" alt="Full Logo (Black)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Full Logo (Black)</h3>
            <p>Black version of our full logo for use on light backgrounds or print materials.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_blk.svg', 'full_logo_black.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_blk.svg', 'full_logo_black.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview dark-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_wht.svg" alt="Full Logo (White)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Full Logo (White)</h3>
            <p>White version of our full logo for use on dark backgrounds or colored materials.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_wht.svg', 'full_logo_white.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/full_logo_wht.svg', 'full_logo_white.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <!-- Icon Logos -->
        <div class="asset-card">
          <div class="asset-preview dark-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/icon_logo_wht.svg" alt="Icon Logo (White)" class="asset-image icon-size" />
          </div>
          <div class="asset-info">
            <h3>Icon Logo (White)</h3>
            <p>White version of our icon mark for use on dark backgrounds, favicons, and small spaces.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/icon_logo_wht.svg', 'icon_logo_white.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/icon_logo_wht.svg', 'icon_logo_white.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview light-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/icon_logo_blk.svg" alt="Icon Logo (Black)" class="asset-image icon-size" />
          </div>
          <div class="asset-info">
            <h3>Icon Logo (Black)</h3>
            <p>Black version of our icon mark for use on light backgrounds, favicons, and small spaces.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/icon_logo_blk.svg', 'icon_logo_black.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/icon_logo_blk.svg', 'icon_logo_black.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <!-- Word Logos -->
        <div class="asset-card">
          <div class="asset-preview light-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/word_logo_blk.svg" alt="Word Logo (Black)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Word Logo (Black)</h3>
            <p>Black version of our wordmark for use on light backgrounds or when only the text portion is needed.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/word_logo_blk.svg', 'word_logo_black.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/word_logo_blk.svg', 'word_logo_black.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview dark-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/word_logo_wht.svg" alt="Word Logo (White)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Word Logo (White)</h3>
            <p>White version of our wordmark for use on dark backgrounds or when only the text portion is needed.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/word_logo_wht.svg', 'word_logo_white.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/logo/word_logo_wht.svg', 'word_logo_white.png')">PNG</button>
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="section-title mt-8">Badges</h2>
      
      <div class="asset-grid">
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/founder_badge.svg" alt="Founder Badge" class="asset-image" style="max-width: 80%; max-height: 80%;" />
          </div>
          <div class="asset-info">
            <h3>Founder Badge</h3>
            <p>Iconic badge displayed next to founder accounts and founder-related communications.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/founder_badge.svg', 'founder_badge.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/founder_badge.svg', 'founder_badge.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/premium_badge.svg" alt="Premium Badge" class="asset-image" style="max-width: 100%; max-height: 100%;" />
          </div>
          <div class="asset-info">
            <h3>Premium Badge</h3>
            <p>Badge displayed next to premium users and premium content throughout the platform.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/premium_badge.svg', 'premium_badge.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/premium_badge.svg', 'premium_badge.png')">PNG</button>
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="section-title mt-8">Mascots & Characters</h2>
      
      <div class="asset-grid">
        <div class="asset-card">
          <div class="asset-preview dark-bg alien-avatar">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/square_avatar_animated.svg" alt="Animated Alien Avatar" class="asset-image" style="width: 180px; height: 180px;" />
          </div>
          <div class="asset-info">
            <h3>Animated Alien Avatar</h3>
            <p>
              This animated alien is used as the default profile avatar when users or artists don't have a custom avatar. The eyes blink to add a playful touch to the user experience.
            </p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/square_avatar_animated.svg', 'animated_alien_avatar.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/square_avatar_animated.svg', 'animated_alien_avatar.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/mascot/Blip_Dj.svg" alt="Blip DJ Mascot" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Blip DJ Mascot</h3>
            <p>Our friendly DJ mascot used in promotional materials and welcome emails.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/mascot/Blip_Dj.svg', 'Blip_Dj.svg')" class="download-button">SVG</button>
              <button @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/mascot/Blip_Dj.svg', 'Blip_Dj.png')" class="download-button png">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/404.svg" alt="404 Page Graphic" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>404 Page Graphic</h3>
            <p>Friendly illustration for 404 "page not found" errors.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/404.svg', '404_graphic.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/404.svg', '404_graphic.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/nothing_found.svg" alt="Search Not Found" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Search Not Found</h3>
            <p>Illustration for search results when no matches are found.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/nothing_found.svg', 'search_not_found.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/nothing_found.svg', 'search_not_found.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/NothingHereYet.svg" alt="Empty State" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Empty State Illustration</h3>
            <p>Friendly illustration for empty states and "nothing found" scenarios.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/NothingHereYet.svg', 'empty_state.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/NothingHereYet.svg', 'empty_state.png')">PNG</button>
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="section-title mt-8">Crystals</h2>
      
      <div class="asset-grid">
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset.svg" alt="Crystal (High Quality)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Crystal (High Quality)</h3>
            <p>Crystal imagery used throughout the platform for achievement indicators and special events.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset.svg', 'crystal_high_quality.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset.svg', 'crystal_high_quality.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset_med.svg" alt="Crystal (Medium Quality)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Crystal (Medium Quality)</h3>
            <p>Lower quality version of the crystal for use in constrained environments or where file size is a concern.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset_med.svg', 'crystal_medium_quality.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset_med.svg', 'crystal_medium_quality.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/success_crystal.svg" alt="Success Crystal" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Success Crystal</h3>
            <p>Special crystal variant used to indicate successful operations and achievements throughout the platform.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/success_crystal.svg', 'success_crystal.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/success_crystal.svg', 'success_crystal.png')">PNG</button>
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="section-title mt-8">Promotional Materials</h2>
      
      <div class="asset-grid">
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/supportbannersm.svg" alt="Support Banner (Small)" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>Support Banner (Small)</h3>
            <p>Compact support banner for limited space applications, sidebars, and mobile displays.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/supportbannersm.svg', 'supportbannersm.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/supportbannersm.svg', 'supportbannersm.png')">PNG</button>
            </div>
          </div>
        </div>
        
        <div class="asset-card">
          <div class="asset-preview default-bg">
            <img src="https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/wesupportpsybanner1.svg" alt="PsyChurch Support Banner" class="asset-image" />
          </div>
          <div class="asset-info">
            <h3>PsyChurch Support Banner</h3>
            <p>Alternative banner design for community support campaigns and partnership initiatives.</p>
            <div class="download-links">
              <button @click="downloadSvg('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/wesupportpsybanner1.svg', 'wesupportpsybanner1.svg')" class="download-button">SVG</button>
              <button class="download-button png" @click="openPngModal('https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/wesupportpsybanner1.svg', 'wesupportpsybanner1.png')">PNG</button>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { downloadSvg } from '@/utils/marketingUtils';
import { preloadSvg } from '@/utils/svgToPngConverter';

export default {
  name: 'MarketingVisualAssets',
  props: {
    // This component will receive these props from the parent component
    assetBaseUrl: {
      type: String,
      default: 'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/'
    }
  },
  data() {
    return {
      preloadedImages: []
    }
  },
  mounted() {
    // Preload all SVG images used in the visual assets
    this.preloadAllSvgs();
  },
  methods: {
    /**
     * Preloads all SVG images used on the page to ensure they are ready
     * for the PNG conversion process
     */
    preloadAllSvgs() {
      console.log('Preloading all SVGs in Visual Assets component...');
      
      // Collect all SVG URLs from the template
      const svgUrls = [
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/success_crystal.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/404.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/nothing_found.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/NothingHereYet.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/founder_badge.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/premium_badge.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/mascot/Blip_Dj.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/crystal_asset_med.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/crystals/success_crystal.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/supportbannersm.svg',
        'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/misc/wesupportpsybanner1.svg'
      ];
      
      // Preload each SVG
      svgUrls.forEach(url => {
        this.preloadSvg(url);
      });
    },
    
    /**
     * Preloads a specific SVG and adds it to the preloaded images array
     * @param {string} url - The URL of the SVG to preload
     */
    async preloadSvg(url) {
      try {
        console.log(`Preloading SVG: ${url}`);
        await preloadSvg(url);
        this.preloadedImages.push(url);
        console.log(`Successfully preloaded: ${url}`);
      } catch (error) {
        console.warn(`Failed to preload SVG: ${url}`, error);
      }
    },

    /**
     * Emits an event to the parent component to open the PNG modal
     * @param {string} svgUrl - The URL of the SVG to convert
     * @param {string} filename - The filename for the download
     */
    openPngModal(svgUrl, filename) {
      console.log(`Requesting to open PNG modal for SVG: ${svgUrl}`);
      // Emit an event to the parent component
      this.$emit('open-png-modal', { svgUrl, filename });
    },
    
    /**
     * Download SVG file directly
     * @param {string} svgUrl - The URL of the SVG to download
     * @param {string} filename - The filename for the download
     */
    downloadSvg(svgUrl, filename) {
      downloadSvg(svgUrl, filename);
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/marketingAssets.scss';

/* Component-specific styles that aren't in the shared file */
.alien-avatar {
  img {
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.download-button.preview {
  background-color: #5F6986;
}
</style> 