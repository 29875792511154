<template>
  <v-dialog
    :value="value"
    max-width="570"
    @input="$emit('input', $event)">
    
    <div class="dialog-wrapper png-size-modal-wrapper">
      <div class="dialog-header">
        <h2>PNG Size Options</h2>
      </div>
      
      <div class="dialog-content">
        <!-- Loading indicator -->
        <div v-if="loading || compressing" class="loading-indicator mb-4">
          <div class="spinner"></div>
          <p>{{ tempMessage || 'Loading image dimensions...' }}</p>
        </div>
        
        <div v-if="!loading && !compressing" class="preset-sizes mb-4">
          <div class="d-flex flex-wrap">
            <!-- Size presets - excluding Custom which will be moved to the end -->
            <button
              v-for="preset in presetSizesWithoutCustom"
              :key="preset.label"
              class="preset-btn"
              :class="{ active: selectedPreset === preset.label }"
              @click="selectPreset(preset)"
            >
              {{ preset.label }}
            </button>
            
            <!-- Multiply buttons -->
            <button
              v-if="dimensionsLoaded && originalWidth > 1 && originalHeight > 1"
              v-for="multiplier in multipliers"
              :key="multiplier.label"
              class="preset-btn"
              :class="{ active: selectedPreset === multiplier.label }"
              @click="applyMultiplier(multiplier.factor)"
            >
              {{ multiplier.label }}
            </button>
            
            <!-- Custom button at the end -->
            <button
              v-if="customPreset"
              class="preset-btn"
              :class="{ active: selectedPreset === 'Custom' }"
              @click="selectPreset(customPreset)"
            >
              Custom
            </button>
          </div>
        </div>
        
        <div v-if="!loading && !compressing" class="custom-size">
          <div class="d-flex align-center">
            <v-text-field
              v-model="width"
              label="Width (px)"
              type="number"
              min="1"
              max="4000"
              outlined
              dense
              class="mr-2"
              :rules="[rules.required, rules.number]"
            ></v-text-field>
            
            <span class="px-2">×</span>
            
            <v-text-field
              v-model="height"
              label="Height (px)"
              type="number"
              min="1"
              max="4000"
              outlined
              dense
              :rules="[rules.required, rules.number]"
            ></v-text-field>
          </div>
          
          <div class="aspect-ratio-checkbox mt-2">
            <label class="custom-checkbox">
              <input type="checkbox" v-model="maintainAspectRatio">
              <span class="checkmark"></span>
              <span class="checkbox-label">Maintain aspect ratio</span>
            </label>
          </div>
          
          <!-- File size estimate - REMOVED -->
          
          <v-alert
            v-if="error"
            type="error"
            dense
            class="mt-2"
          >
            {{ error }}
          </v-alert>
        </div>
      </div>
      
      <div class="dialog-actions">
        <button class="tetriatary_btn" @click="closeModal">Cancel</button>
        <button 
          class="c2a_btn" 
          :disabled="!isValid || loading || isDownloading || compressing"
          @click="downloadPng"
        >
          <span v-if="isDownloading || compressing">Processing...</span>
          <span v-else>Download PNG</span>
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { convertAndDownloadPng, getSvgDimensions } from '@/utils/svgToPngConverter';

export default {
  name: 'PngSizeModal',
  props: {
    svgUrl: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      width: 800,
      height: 600,
      originalWidth: 0,  // Still needed for multipliers and aspect ratio
      originalHeight: 0, // Still needed for multipliers and aspect ratio
      maintainAspectRatio: true,
      loading: false,
      compressing: false,
      dimensionsLoaded: false, // Used to determine if multiplier buttons should be shown
      isDownloading: false,
      error: null,
      tempMessage: null,
      selectedPreset: 'Original Size',
      
      presetSizes: [
        { label: 'Small (256px)', width: 256, height: 256 },
        { label: 'Medium (512px)', width: 512, height: 512 },
        { label: 'Large (1024px)', width: 1024, height: 1024 },
        { label: 'Original Size', width: null, height: null },
        { label: 'Custom', width: null, height: null }
      ],
      
      multipliers: [
        { factor: 2, label: '2x' },
        { factor: 3, label: '3x' },
        { factor: 4, label: '4x' }
      ],
      
      rules: {
        required: value => !!value || 'Required',
        number: value => {
          const num = parseInt(value);
          return (num > 0 && num <= 4000) || 'Must be between 1 and 4000'
        }
      }
    };
  },
  
  computed: {
    aspectRatio() {
      return this.originalWidth / this.originalHeight;
    },
    
    presetSizesWithoutCustom() {
      return this.presetSizes.filter(preset => preset.label !== 'Custom');
    },
    
    customPreset() {
      return this.presetSizes.find(preset => preset.label === 'Custom');
    },
    
    isValid() {
      return this.width && this.height && 
             this.width > 0 && this.width <= 4000 &&
             this.height > 0 && this.height <= 4000;
    },
    
    filenameWithDimensions() {
      // Get file extension
      const extension = this.filename.substring(this.filename.lastIndexOf('.')) || '.png';
      
      // Get base filename without extension
      const baseFilename = this.filename.substring(0, this.filename.lastIndexOf('.'));
      
      // Return the new filename with dimensions
      return `${baseFilename}_${this.width}x${this.height}${extension}`;
    }
  },
  
  watch: {
    value(val) {
      if (val) {
        this.loadSvgDimensions();
      } else {
        this.error = null;
      }
    },
    
    width(val) {
      if (this.maintainAspectRatio && val && this.aspectRatio) {
        const newWidth = parseInt(val);
        if (!isNaN(newWidth)) {
          // Prevent recursive updates by checking if the new calculated height is actually different
          const newHeight = Math.round(newWidth / this.aspectRatio);
          if (newHeight !== parseInt(this.height)) {
            this.height = newHeight;
          }
        }
      }
    },
    
    height(val) {
      if (this.maintainAspectRatio && val && this.aspectRatio) {
        const newHeight = parseInt(val);
        if (!isNaN(newHeight)) {
          // Prevent recursive updates by checking if the new calculated width is actually different
          const newWidth = Math.round(newHeight * this.aspectRatio);
          if (newWidth !== parseInt(this.width)) {
            this.width = newWidth;
          }
        }
      }
    }
  },
  
  methods: {
    async loadSvgDimensions() {
      this.loading = true;
      this.error = null;
      this.dimensionsLoaded = false;
      
      // Show a loading message
      this.tempMessage = 'Loading image dimensions...';
      
      try {
        console.log(`PngSizeModal: Loading dimensions for ${this.svgUrl}`);
        
        // Allow time for the UI to update before starting the potentially slow operation
        await new Promise(resolve => setTimeout(resolve, 50));
        
        // Use our improved getSvgDimensions utility with timeout to ensure proper loading
        const dimensions = await Promise.race([
          getSvgDimensions(this.svgUrl),
          new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout loading dimensions')), 10000))
        ]);
        
        // Log the dimensions we got
        console.log(`PngSizeModal: Retrieved dimensions: ${dimensions.width}x${dimensions.height}`);
        
        // Validate dimensions before using them
        if (dimensions && dimensions.width > 1 && dimensions.height > 1) {
          this.originalWidth = dimensions.width;
          this.originalHeight = dimensions.height;
          this.dimensionsLoaded = true;
        } else {
          console.warn('Invalid dimensions received:', dimensions);
          this.originalWidth = 512;
          this.originalHeight = 512;
          // Use a more subtle approach - don't show error, just set dimensionsLoaded to false
          // so the original dimensions don't display
          this.dimensionsLoaded = false;
        }
        
        // Set initial dimensions based on original with a reasonable max size
        const maxDimension = 512; // Default to medium size
        
        if (this.originalWidth >= this.originalHeight) {
          this.width = Math.min(this.originalWidth, maxDimension);
          this.height = Math.round(this.width * (this.originalHeight / this.originalWidth));
        } else {
          this.height = Math.min(this.originalHeight, maxDimension);
          this.width = Math.round(this.height * (this.originalWidth / this.originalHeight));
        }
        
        // Update the "Original Size" preset
        const originalSizePreset = this.presetSizes.find(p => p.label === 'Original Size');
        if (originalSizePreset) {
          originalSizePreset.width = this.originalWidth;
          originalSizePreset.height = this.originalHeight;
        }
        
        // Select default preset - now Original Size
        this.selectPreset(originalSizePreset || this.presetSizes.find(p => p.label === 'Original Size'));
      } catch (error) {
        console.error('Failed to load SVG image dimensions:', error);
        // Don't show error to the user, just use defaults silently
        this.originalWidth = 512;
        this.originalHeight = 512;
        this.width = 512;
        this.height = 512;
        
        // Select default preset - now Original Size
        this.selectPreset(this.presetSizes.find(p => p.label === 'Original Size'));
      } finally {
        this.loading = false;
        this.tempMessage = null;
      }
    },
    
    selectPreset(preset) {
      this.selectedPreset = preset.label;
      
      if (preset.label === 'Original Size' && this.originalWidth && this.originalHeight) {
        this.width = this.originalWidth;
        this.height = this.originalHeight;
      } else if (preset.width && preset.height) {
        this.width = preset.width;
        this.height = preset.height;
      }
    },
    
    closeModal() {
      this.$emit('input', false);
    },
    
    async downloadPng() {
      if (!this.isValid) return;
      
      this.isDownloading = true;
      this.error = null;
      
      try {
        this.tempMessage = 'Converting SVG to PNG...';
        
        // Convert SVG to PNG directly
        const pngDataUrl = await convertAndDownloadPng(
          this.svgUrl, 
          parseInt(this.width), 
          parseInt(this.height),
          null // Don't trigger automatic download
        );
        
        // Create download link
        const link = document.createElement('a');
        link.href = pngDataUrl;
        link.download = this.filenameWithDimensions;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        this.isDownloading = false;
        this.closeModal();
      } catch (error) {
        console.error('PNG download error:', error);
        this.error = 'An error occurred during conversion. Please try again.';
        this.isDownloading = false;
      }
    },
    
    applyMultiplier(factor) {
      if (this.dimensionsLoaded && this.originalWidth > 1 && this.originalHeight > 1) {
        // Find the multiplier object that matches the factor
        const multiplier = this.multipliers.find(m => m.factor === factor);
        if (multiplier) {
          this.selectedPreset = multiplier.label;
        }
        
        // Apply the multiplier to calculate new dimensions
        this.width = Math.round(this.originalWidth * factor);
        this.height = Math.round(this.originalHeight * factor);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.png-size-modal-wrapper {
  background-color: #282C47;
  border-radius: 12px;
  overflow: hidden;
}

.dialog-header {
  background-color: rgba(40, 44, 71, 0.7);
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(95, 105, 134, 0.3);
  
  h2 {
    color: #fff;
    font-size: 1.25rem;
    margin: 0;
  }
}

.dialog-content {
  padding: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: none !important;
  /* Override the mixin styles */
  border-top: none !important;
}

/* Loading indicator styles */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  
  .spinner {
    border: 3px solid rgba(32, 196, 245, 0.2);
    border-top: 3px solid #20C4F5;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin: 0;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.preset-sizes {
  margin-bottom: 1.5rem;
}

.preset-btn {
  background-color: rgba(40, 44, 71, 0.7);
  border: 1px solid rgba(95, 105, 134, 0.5);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(40, 44, 71, 0.9);
  }
  
  &.active {
    background-color: rgba(32, 196, 245, 0.15);
    border-color: #20C4F5;
    color: #fff;
  }
}

.custom-size {
  margin-bottom: 1.5rem;
  /* Remove any bottom border */
  border-bottom: none;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1.75rem;
  cursor: pointer;
  user-select: none;
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: rgba(40, 44, 71, 0.7);
    border: 1px solid rgba(95, 105, 134, 0.5);
    border-radius: 2px;
  }
  
  &:hover input ~ .checkmark {
    background-color: rgba(40, 44, 71, 0.9);
  }
  
  input:checked ~ .checkmark {
    background-color: #20C4F5;
    border-color: #20C4F5;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox-label {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.9rem;
  }
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 1.5rem;
  /* Apply only one border at the top */
  border-top: 1px solid rgba(95, 105, 134, 0.3) !important;
  margin-top: 0 !important;
  padding-top: 1rem !important;
}

.tetriatary_btn {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);
  }
}

.c2a_btn {
  background-color: #20C4F5;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: darken(#20C4F5, 10%);
  }
  
  &:disabled {
    background-color: rgba(32, 196, 245, 0.5);
    cursor: not-allowed;
  }
}
</style> 