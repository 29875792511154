<template>
  <v-card flat class="transparent-bg">
    <v-card-text>
      <h2 class="section-title">Embeddable Content</h2>
      
      <div class="wide-asset-container">
        <!-- Founders Club Landing Page -->
        <div class="asset-card wide-card">
          <div class="asset-preview default-bg landing-preview" ref="preview">
            <iframe src="/marketing-assets/psychurch-embed.html" title="Founders Club Landing Page" class="landing-iframe" scrolling="no" allowtransparency="true"></iframe>
            <div class="dimensions-display">{{ previewDimensions }}</div>
          </div>
          <div class="asset-info">
            <h3>Founders Club Landing Page</h3>
            <p>A responsive, embeddable landing page promoting the Founders Club initiative. Features the PsyChurch branding and messaging about joining the community.</p>
            <div class="embed-instructions">
              <h4>Embed Instructions</h4>
              <p>Copy the code below to embed this landing page on your website:</p>
              <div class="embed-code-container">
                <pre class="embed-code"><code>&lt;iframe src="https://psychurch.org/marketing-assets/psychurch-embed.html" 
        width="100%" height="600px" 
        style="border: none; overflow: hidden;" 
        scrolling="no" allowtransparency="true"&gt;&lt;/iframe&gt;</code></pre>
                <button @click="copyEmbedCode" class="copy-button" :class="{ 'copied': isCopied }">
                  {{ isCopied ? 'Copied!' : 'Copy' }}
                </button>
              </div>
              <p class="embed-note">Adjust width and height values to fit your layout as needed.</p>
            </div>
            <div class="download-links">
              <button @click="downloadFile('/marketing-assets/psychurch-embed.html', 'psychurch-embed.html')" class="download-button">HTML</button>
              <button @click="previewFile('/marketing-assets/psychurch-embed.html')" class="download-button preview">Preview</button>
            </div>
          </div>
        </div>
      </div>
      
      <h2 class="section-title mt-8">Social Media Widgets</h2>
      
      <div class="asset-grid">
        <div class="asset-card">
          <div class="asset-preview default-bg social-preview">
            <div class="social-widget-placeholder">
              <div class="widget-header">
                <div class="widget-logo"></div>
                <div class="widget-title">PsyChurch Event Feed</div>
              </div>
              <div class="widget-content">
                <div class="widget-item"></div>
                <div class="widget-item"></div>
                <div class="widget-item"></div>
              </div>
            </div>
          </div>
          <div class="asset-info">
            <h3>Event Feed Widget</h3>
            <p>Embed a dynamic feed of upcoming PsyChurch events on your website. Automatically updates when new events are added to the platform.</p>
            <div class="embed-instructions">
              <h4>Embed Instructions</h4>
              <p>Copy the code below to embed the event feed on your website:</p>
              <pre class="embed-code"><code>&lt;script src="https://psychurch.org/widgets/event-feed.js"&gt;&lt;/script&gt;
&lt;div id="psychurch-events" data-count="5" data-theme="dark"&gt;&lt;/div&gt;</code></pre>
              <p class="embed-note">Customize options with data attributes: data-count (number of events), data-theme (dark/light)</p>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MarketingEmbeddings',
  props: {
    // This component will receive these props from the parent component
    assetBaseUrl: {
      type: String,
      default: 'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/'
    }
  },
  data() {
    return {
      previewDimensions: '600 × 600',
      isCopied: false
    }
  },
  mounted() {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = Math.round(entry.contentRect.width);
        const height = Math.round(entry.contentRect.height);
        this.previewDimensions = `${width} × ${height}`;
        
        // Update the embed code with new dimensions
        const embedCode = this.$el.querySelector('.embed-code code');
        if (embedCode) {
          let updatedCode = embedCode.textContent
            .replace(/width="[^"]*"/, `width="${width}px"`)
            .replace(/height="[^"]*"/, `height="${height}px"`);
          embedCode.textContent = updatedCode;
        }
      }
    });
    
    observer.observe(this.$refs.preview);
  },
  methods: {
    downloadFile(url, filename) {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    previewFile(url) {
      window.open(url, '_blank');
    },
    async copyEmbedCode() {
      try {
        const embedCode = this.$el.querySelector('.embed-code code').textContent;
        await navigator.clipboard.writeText(embedCode);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
        // Fallback copy method
        const textArea = document.createElement('textarea');
        textArea.value = this.$el.querySelector('.embed-code code').textContent;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand('copy');
          this.isCopied = true;
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        } catch (err) {
          console.error('Fallback copy failed:', err);
        }
        document.body.removeChild(textArea);
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/marketingAssets.scss';

/* Component-specific styles */
.landing-preview {
  height: 600px;
  padding: 0;
  margin: 0;
  overflow: visible;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  resize: both;
  min-width: 470px;
  max-width: 100%;
  min-height: 260px;
  cursor: se-resize;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 16px;
    height: 16px;
    border-right: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    pointer-events: none;
  }
}

.landing-iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}

.dimensions-display {
  position: absolute;
  bottom: 8px;
  right: 24px;
  background: rgba(0, 0, 0, 0.7);
  color: rgba(255, 255, 255, 0.9);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: monospace;
  pointer-events: none;
  z-index: 1;
}

.download-button.preview {
  background-color: #5F6986;
}

.embed-instructions {
  background-color: rgba(7, 16, 31, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  padding: 16px;
  margin: 15px 0;
  font-size: 0.9em;
}

.embed-instructions h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.embed-code {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 12px;
  margin: 10px 0;
  overflow-x: auto;
  font-family: monospace;
  font-size: 13px;
  line-height: 1.5;
  white-space: pre-wrap;
  color: #A0ABD1;
}

.embed-note {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
  margin: 8px 0 0 0;
}

.social-preview {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-widget-placeholder {
  width: 90%;
  height: 90%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.widget-header {
  height: 60px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.widget-logo {
  width: 36px;
  height: 36px;
  background: #20C4F5;
  border-radius: 50%;
  margin-right: 10px;
}

.widget-title {
  color: white;
  font-weight: 600;
}

.widget-content {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.widget-item {
  height: 50px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

.wide-asset-container {
  width: 100%;
  margin-bottom: 2rem;
}

.wide-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 0;
}

.wide-card .asset-preview {
  flex: 0 1 auto;
  width: 370px;
  height: 600px;
  min-height: 260px;
  min-width: 470px;
  margin: 0;
  border-radius: 8px 0 0 8px;
  border-right: none;
  padding: 0;
  overflow: auto;
}

.wide-card .asset-info {
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

/* Responsive behavior */
@media (max-width: 1200px), (min-width: 1201px) and (min-height: 800px) {
  .wide-card {
    flex-direction: column;
  }
  
  .wide-card .asset-preview {
    width: 100%;
    border-radius: 8px 8px 0 0;
    min-height: 260px;
  }
  
  .wide-card .asset-info {
    max-width: 100%;
  }
}

.embed-code-container {
  position: relative;
  margin: 10px 0;
}

.embed-code {
  padding-right: 100px;
}

.copy-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(32, 196, 245, 0.2);
  border: 1px solid #20C4F5;
  color: #20C4F5;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 60px;
  outline: none;

  &:hover {
    background: rgba(32, 196, 245, 0.3);
  }

  &.copied {
    background: rgba(76, 175, 80, 0.2) !important;
    border-color: #4CAF50 !important;
    color: #4CAF50 !important;
  }
}
</style> 